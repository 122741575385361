.react-calendar.fluid-calendar {
  width: 100%;
  font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
  font-size: 1.2rem;
  max-width: 1000px;
  border: 0;
  button {
    font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
    font-weight: 300;
    padding: calc(1/28*100%) 0;
    height: 0;
    line-height: 0;
    position: relative;
  }
  .react-calendar__navigation__arrow svg {
    margin-top: -12px;
  }
}

.fluid-calendar {
  .react-calendar__navigation {
    height: auto;
    background: #1976D2;
    color: #fff;
  }
  .react-calendar__tile--active {
    background: #F44336;
    color: #fff;
  }
  .react-calendar__navigation button {
    color: #fff;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #ccc;
  }
  .react-calendar__month-view__days__day--weekend {
    color: #F44336;
  }
  .react-calendar__tile--active.react-calendar__tile--active {
    color: #fff;
  }
  button.react-calendar__tile:enabled {
    &:hover, &:focus {
      background: #eee;
    }
  }
  .react-calendar__tile--active {
    &:hover, &:focus {
      background: #F44336;
    }
  }
  button.react-calendar__tile--active:enabled {
    &:hover, &:focus {
      background: #F44336;
    }
  }
}

.react-calendar.fluid-calendar time + span {
  border-radius: 50%;
  text-align: center;
  position: absolute;
  top: 5px;
  right: 5px;
  border: solid 1px #ccc;
  background: #eee;
  color: #111;
  display: block;
  padding: 10px 0;
  height: 0;
  width: 22px;
  font-size: 80%;
  line-height: 0.2;
}

.fluid-calendar .react-calendar__navigation button:enabled {
  &:hover, &:focus {
    background: #0D47A1;
  }
}